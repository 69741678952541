import './src/styles/global.scss'
import React from 'react'
import { Auth0Provider } from '@auth0/auth0-react'
import { navigate } from 'gatsby'

const onRedirectCallback = (appState) => {
    // Use Gatsby's navigate method to replace the url
    navigate(appState?.returnTo || '/', { replace: true })
}

export const wrapRootElement = ({ element }) => {
    return (
        <Auth0Provider
            domain='fw-prod-bling.au.auth0.com'
            clientId='aXJXanAnX81vzKsPsjpBxQxWM0znb7so'
            authorizationParams={{
                redirect_uri: window.location.origin
            }}
            onRedirectCallback={onRedirectCallback}
        >
            {element}
        </Auth0Provider>
    )
}